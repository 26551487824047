declare const _langID: number;
declare const _websiteID: number;
declare const _currencyID: number;
declare const _currencyCulture: string;
declare const _deliveryCountryID: number;
declare const _apiBaseURL: string;
declare const _productImageURL: string;
declare const _cdn: string;
declare const langCult: string;

export const global = {
    languageId: _langID,
    websiteId: _websiteID,
    customerId: '123AB',
    currencyId: _currencyID,
    currencyCulture: _currencyCulture,
    delCountryId: _deliveryCountryID,
    apiBaseURL: _apiBaseURL,
    productImageURL: _productImageURL,
    cdn: _cdn,
    langCult: langCult,
};
