import { createRoot } from 'react-dom/client';

import MainSearch from './mainSearch';
import RecentlyViewed from './recentlyViewed';

const container1 = document.getElementById('recentlyViewed');
if (container1) {
    const root1 = createRoot(container1!);
    root1.render(<RecentlyViewed />);
}

const container2 = document.getElementById('searchBar');
if (container2) {
    const root2 = createRoot(container2!);
    root2.render(<MainSearch />);
}
