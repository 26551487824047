import { useEffect, useState } from 'react';

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState<{
        width: undefined | number;
    }>({
        width: undefined,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
            });
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        // remove event on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}
