import { SearchBar } from '../../components/ui';

// generate random number id so react won't complain about multiple
const id = Math.floor(Math.random() * 1000000);

export default function MainSearch() {
    return (
        <div>
            <SearchBar key={id} />
        </div>
    );
}
