import React, { ReactNode } from "react";

interface HeadingProps {
    titleText?: string;
    titleExtraHtml?: ReactNode;
    subtitleText?: string;
    className?: string;
}

const Heading = ({
    titleText,
    titleExtraHtml,
    subtitleText,
    className,
}: HeadingProps) => {
    return (
        <div className={`${className ?? ""} heading`}>
            {titleText && (
                <div className="heading__title">
                    <h3 className="heading__title-text">{titleText}</h3>
                    <div className="heading__title-extra">{titleExtraHtml}</div>
                </div>
            )}
            {subtitleText && (
                <div className="heading__subtitle">
                    <p className="heading__subtitle-text">{subtitleText}</p>
                </div>
            )}
        </div>
    );
};

export default Heading;
